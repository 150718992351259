<template>
  <section class="content-section">
  <template v-if="dispDiv=='reqsubjOnly'">
      <div></div>
  </template>
  <template v-else>
      <header class="section-header">
        <h4 class="title">{{ title }}</h4>
      </header>
  </template>
    <div class="section-content">
      <div v-for="(item, index) in items" class="content-item" :key="index" :class="{'kb-line-through': isDashLine(item.drgb)}">
        <div class="list-title">
          <sup>{{ getDivText(item.gubun) }}  {{  item.gjCdp ? " / " + item.gjCdp + " / " + item.courseDivNm : ""}}</sup>
          <h5>{{ item.subjnm }}</h5>
        </div>
        <ol class="adc-list wd-66">
          <li>
            <h6>연수기간</h6>
            <p v-if="item.gubun === 'P'">{{item.ended}}</p>
            <p v-else>{{ item.started }} {{item.started ? '~' : ''}} {{ item.ended }}</p>
          </li>
          <li>
            <h6>취득성적</h6>
            <p>{{ item.paccept ? item.paccept : '-' }}</p>
          </li>
          <li>
            <h6>비고</h6>
            <p v-html="item.memo"></p>
          </li>
        </ol>
      </div>
<!--      <div v-for="(item, index) in transitionalItems" class="content-item" :key="index">-->
<!--        <div class="list-title">-->
<!--          <sup>{{ getDivText(item.gubun) }}</sup>-->
<!--          <h5></h5>-->
<!--        </div>-->
<!--        <ol class="adc-list wd-66">-->
<!--          <li>-->
<!--            <h6>연수기간</h6>-->
<!--            <p>{{ item.ended }}</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <h6>취득성적</h6>-->
<!--            <p>{{ item.paccept ? item.paccept : '-'  }}</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <h6>비고</h6>-->
<!--            <p v-html="item.memo"></p>-->
<!--          </li>-->
<!--        </ol>-->
<!--      </div>-->

      <div class="content-item">
        <p v-if="divCds.includes('R') && session.jbgdCd && session.jbgdCd.indexOf('L') === 0" class="silver">
          {{ session.lrnerNm }}님은 직무필수과정 총 {{result.jikmuReqCountDefault}}개 중 {{ result.pilsupassCnt }}개를 수료하셨습니다
        </p>
        <p class="silver" v-if="divCds.includes('P') && (result.jkgupGradeBookingId || result.jkgupGradeLrnMapngRsltSumupSn)">
          {{ session.lrnerNm }}님은 {{ result.preclassname }}을 수료하셨습니다
        </p>
      </div>
    </div>
  </section>
</template>
<script>

import {capabilityGradeTableSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "MobileCapabilityGradeTable",
  components: {},
  props: {
    divCds: Array,
    items: Array,
    result: Object,
    dispDiv: String,
  },
  setup: capabilityGradeTableSetup
}
</script>
