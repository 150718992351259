import {timestampToDateFormat, ymdStrToDateFormat} from '@/assets/js/util';

export const getLicensePassYmd = (license) => {
  if(license.passAnnonYmd){
    return ymdStrToDateFormat(license.passAnnonYmd, 'yyyy.MM.dd');
  }else if(license.lcsnElicenseamYmd){
    return ymdStrToDateFormat(license.lcsnElicenseamYmd, 'yyyy.MM.dd');
  }else if(license.lcsnRegYmd){
    return ymdStrToDateFormat(license.lcsnRegYmd, 'yyyy.MM.dd');
  }
  return license.regDt ? timestampToDateFormat(license.regDt,'yyyy.MM.dd') : '-';
}

const oneJobGradeCodes = ['L0', 'L1']
const twoJobGradeCodes = ['L2', 'L3', 'S2', 'S3']

const threeJobGradeCodes = ['L0', 'L1', 'L2', 'L3']
const fourJobGradeCodes = ['L0', 'L1', 'L2', 'L3', 'S1', 'S2', 'S3']

// {
//   courseDivCd: ['3','4'],
//       tableDivCd: 'g'
// }

export const getDivStands = (jbgdCd, curtJbgdPromoYmd) => {
  const result = [];

  if (oneJobGradeCodes.includes(jbgdCd)) {
    result.push({
      courseDivCds: ['1'],
      tableDivCd: 'b',
      isReflected : true
    });
  }

  if (twoJobGradeCodes.includes(jbgdCd)) {
    result.push({
      courseDivCds: ['2'],
      tableDivCd: 'b',
      isReflected : true
    });
  }

  // L0 => L1 로 승격했을 경우 신임과정을 노출해야된다.
  if (jbgdCd === 'L1' && curtJbgdPromoYmd) {
    result.push({
      courseDivCds: ['1'],
      tableDivCd: 'b',
      isReflected : false
    });
  }

  const gradeCourseDivCds = [];
  if (fourJobGradeCodes.includes(jbgdCd)) gradeCourseDivCds.push('4');
  if (threeJobGradeCodes.includes(jbgdCd)) gradeCourseDivCds.push('3');

  if (gradeCourseDivCds.length > 0) {
    result.push({
      courseDivCds: gradeCourseDivCds,
      tableDivCd: 'g',
      isReflected : false
    });
  }

  return result;
}

export const getGradeScore = (result, isExpert) => {
  if (result) {

    return (result.jkgupGradeBookingId > 0 || result.jkgupGradeLrnMapngRsltSumupSn > 0) && (isExpert || result.pilsupassCnt >= result.jikmuReqCountDefault) ? result.jkgupReqGrade : 0;
  }
  return 0;
}
